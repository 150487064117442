import styled from "styled-components/macro";

interface Props {
  className?: string;
  children: React.ReactNode;
  delay?: number;
  duration?: number;
}

const SlideDown = ({ className, children, delay, duration }: Props) => {
  return <StyledSlideDown {...{ className, delay, duration }}>{children}</StyledSlideDown>;
};

export default SlideDown;

const StyledSlideDown = styled.div.attrs<Props>(({ delay, duration }) => ({
  style: {
    animation: `slide-down ${duration ?? 0.5}s ease forwards`,
    animationDelay: `${delay ?? 0}s`,
  },
}))<Props>`
  @keyframes slide-down {
    0% {
      opacity: 1;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(-0%);
    }
  }
  opacity: 0;
`;
